import React from 'react'
import { Box, Grid } from '@mui/material'
import { OwnerInformation, OwnerPrice } from './components'
import ButtonStyled from 'components/ButtonStyled'
import { Link } from 'react-router-dom'
import  { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

const Information = ({content}) => {
    const { t } = useTranslation("translate");
    return (
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}
            sx={{
                width: '100%'
            }}
        >

            <Grid container columns={{xs:12,sm:12, md:12, lg:12, xl:12}} 
                sx={{
                    width: '100%'
                }}
                justifyContent='flex-start'
            >
                <Grid item xs={12} sm={12} md={10} lg={10} xl={8}
                    sx={{
                        width: '100%'
                    }}
                >
                    <Grid container columns={{xs:12,sm:12, md:12, lg:12, xl:12}} 
                        sx={{
                            width: '100%'
                        }}
                    >
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{width: '100%'}}>
                            <center>
                            <Box 
                                component='h2'
                                sx={{
                                    fontSize: '66px',
                                    letterSpacing: '-0.02em',
                                    msWordBreak: 'break-word',
                                    marginTop: '0px',
                                    color: '#000',
                                    marginLeft: '10px',
                                    marginBottom: '1rem',
                                    '@media screen and (max-width: 768px)': {
                                        fontSize: '25px',
                                    }
                                }}
                                title={content.metadata.json_data.name}
                            >
                                    {
                                        String(content.metadata.json_data.name).length <= 20 ?
                                        content.metadata.json_data.name
                                        :
                                        String(content.metadata.json_data.name).substring(0,18) + '...'

                                    }
                                </Box>
                            </center>
                        </Grid>

                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{width: '100%'}}>
                            <center>
                                <OwnerInformation content={content} />
                            </center>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{width: '100%'}}>
                            <center>
                            {content.on_auction || content.on_sale &&
                                <OwnerPrice content={content} />
                            }
                            </center>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{width: '100%'}}>
                            <center>
                                <Box 
                                    sx={{
                                        marginTop:'1rem'
                                    }}
                                >
                                    <ButtonStyled 
                                        component={Link} 
                                        text={t('home.view_nft')}
                                        width='auto' 
                                        to={`/nft?address=${content.collection_key}&token_id=${content.token_id}`}
                                    />
                                </Box>
                            </center>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
  )
}

Information.propTypes = {
    content: PropTypes.object,
}

export default Information