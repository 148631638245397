import React, { Suspense, useState } from 'react'
import { Box, Divider } from '@mui/material'
import SideBar from 'components/SideBar'
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ErrorBoundary from 'components/ErrorBoundary';
import CollectionCard from 'components/CollectionCard';
import ProfileCard from 'components/ProfileCard';
import {useFetch} from 'hooks/useFetch'
import styled from '@emotion/styled'
import LoaderCollection from 'components/LoaderCollection';
import ErrorMessage from 'components/ErrorMessage'
import LoaderNFT from 'components/LoaderNFT';
import axios from 'axios';
import NFTsCard from 'components/NFTsCard/NFTsCard';
//import axios from 'axios';

const ActiveTag = styled(Box)`
    border-bottom: ${props => props.active == "true" ? '3px solid #000' : 'null'};
    font-weight: ${props =>props.active == "true" ? 'bold' : 'null'};
    cursor: pointer;
    ${props => props.styles}
`

const Explore = () => {
    const { t } = useTranslation("translate");
    const {pathname:path} = useLocation();
    const location = useLocation()
    console.log("debug params::",path);
    const query = new URLSearchParams(location.search)
    const slug = query.get('SLUG')
    const viewAll = query.get('view_all')
    // console.log(slug)
    // console.log(viewAll)
    //const [slug, setSlug] = useState(query.get('SLUG')||'')
    const [byNFT, setByNFT] = useState( (viewAll) ? (viewAll.includes('collections')) ? false :true : true)
    const [byProfile, setByProfile] = useState(false)
    const [byCollection, setByCollection] = useState((viewAll) ? (viewAll.includes('collections')) ? true :false : false)
    const [activeNFT, setActiveNFT] = useState((viewAll) ? (viewAll.includes('collections')) ? false :true : true)
    const [activeCollection, setActiveCollection] = useState((viewAll) ? (viewAll.includes('collections')) ? true :false : false)
    const [activeProfile, setActiveProfile] = useState(false)
    const project = `${process.env.REACT_APP_URL_API}/collection?domain=${process.env.REACT_APP_DOMAIN}&key_name=NEWS&key_val=NEWS&limit=1000&page=0&order=created`
    let nft = `${process.env.REACT_APP_URL_API}/nft?domain=${process.env.REACT_APP_DOMAIN}&limit=50&page=0&order=created&key_name=${(slug) ? 'SLUG':'SEARCH'}&key_val=${(slug) ? slug:'news'}`
    let {data, error, loading} = useFetch(project)
    let {data:NFTData, error:NFTError, loading:NFTLoading} = useFetch(nft)
    // console.log('error', nft, NFTData,NFTError )
    const [filters,setFilters] = useState([])
    const [newData,setNewData] = useState(null)
    const [loaderFilters,setLoaderFilters] = useState(false)
    const handleRequestWithFilters = async (newFilters,added) => {
        console.log(filters)
        console.log(newFilters)
        setLoaderFilters(true)
        setNewData(null)
        setFilters(filters => {
            newFilters.forEach((filter) => {
                let old_filter = filters.find(x => x.key_name == filter.key_name)
                if (old_filter) {
                    let index = filters.indexOf(old_filter)
                    filters.splice(index,1)
                }
                console.log('fil added',added)
                if(added) {
                    filters.push(filter)
                }
            })
            console.log('filers 1', filters)
            return filters
        })
        console.log('filers 2', filters)
        let nft = `${process.env.REACT_APP_URL_API}/nft?domain=${process.env.REACT_APP_DOMAIN}&limit=10&page=0&order=created&key_name=${(slug) ? 'SLUG':'SEARCH'}&key_val=${(slug) ? slug:'news'}`
        filters.forEach((filter) => {
            nft += "&key_name="+filter.key_name+"&key_val="+filter.key_val
        })
        axios.get(nft).then((response) => {
            data = response.data
            setNewData(data)
            setLoaderFilters(false)
        })
        //data, NFTError, NFTLoading = useFetch(nft)
    }

    const sortby = (str)=>{
        if(str === "nft"){
            setByNFT(true)
            setByProfile(false)
            setByCollection(false)
            setActiveNFT(true)
            setActiveCollection(false)
            setActiveProfile(false)
        }
        if(str === "collection"){
            setByNFT(false)
            setByProfile(false)
            setByCollection(true)
            setActiveNFT(false)
            setActiveCollection(true)
            setActiveProfile(false)
        }
        if(str === "profile"){
            setByNFT(false)
            setByProfile(true)
            setByCollection(false)
            setActiveNFT(false)
            setActiveCollection(false)
            setActiveProfile(true)
        }
    }

    React.useEffect(() => {
        if(NFTLoading){
            setLoaderFilters(true)
        }else{
            setLoaderFilters(false)
        }
    }, [NFTLoading])

    return (
        <>
            <Box 
                component='h1'
                sx={{
                    color:'#000', 
                    fontSize:'1.9vw',
                    textAlign:'center',
                    margintop:'-14px',
                    '@media screen and (max-width: 600px)': {
                        fontSize:'7vw',
                        margintop:'0px',
                    }
                }}
            > 
                {t("gallery.title")}
            </Box>
            <Box display='flex' justifyContent='flex-start' sx={{gap:'1rem', marginLeft:'1.5rem'}}>
                <ActiveTag active={activeNFT+''} onClick={()=>sortby('nft')}>{t('explore.nft_tab')}</ActiveTag>
                <ActiveTag active={activeCollection+''} onClick={()=>sortby('collection')}>{t('explore.collection_tab')}</ActiveTag>
                <Box
                    sx={{
                        display:'none'
                    }}
                >
                    <ActiveTag active={activeProfile+''} onClick={()=>sortby('profile')}>{t('explore.profile_tab')}</ActiveTag> 
                </Box>               
            </Box>
            <Divider />
            <Box
                display='flex'
                justifyContent='space-between'
                sx={{
                    '@media screen and (max-width: 750px)': {
                        flexDirection: 'column',
                    }
                }}
            >
                {(byNFT || byProfile) && 
                <SideBar 
                    //filters={[]}
                   // setFilters={setFilters}
                    isNFT={byNFT}
                    isProfile={byProfile}
                    handleRequestWithFilters={handleRequestWithFilters} 
                />}
                <Box display='flex' justifyContent='center' sx={{width:'100%'}}>

                            {
                                byNFT && 
                                <ErrorBoundary fallback={<div>error</div>}>
                                    <Suspense fallback={null}>
                                        {byNFT && (NFTLoading || loaderFilters) && <LoaderNFT />}
                                        {byNFT && NFTError && <ErrorMessage error={NFTError.message} />}
                                        {byNFT && NFTData && !loaderFilters &&<NFTsCard content={(newData) ? newData : NFTData} limit={NFTData.length} />}
                                        {byProfile && <ProfileCard />}
                                    </Suspense>
                                </ErrorBoundary>
                            }

                            {
                                byCollection && 
                                <ErrorBoundary fallback={<div>error</div>}>
                                    <Suspense fallback={null}>
                                        {byCollection && loading && <LoaderCollection />}
                                        {byCollection && error && <ErrorMessage error={error.message} />}
                                        {byCollection && data && <CollectionCard content={data} />}
                                    </Suspense>
                                </ErrorBoundary>
                            }
                    
                </Box>
            </Box>
        </>
    )
}

export default Explore